import { FloatingOverlay } from '@floating-ui/react';
import styled from '@emotion/styled';
import { boxShadow, overlayColor } from '../../helpers/styleHelpers';
import { breakpoint } from '../../../themes/stena-recycling/breakpoint';
import { spacing } from '../../../themes/stena-recycling/spacing';
import { colors } from '../../../themes/stena-recycling/colors';

export const ModalWrapper = styled.div`
  position: fixed;
  z-index: 7;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;

  background-color: ${overlayColor};

  @media (max-width: ${breakpoint.medium}px) {
    padding: 0;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: ${spacing.mediumHigh};
  box-sizing: border-box;

  @media (max-width: ${breakpoint.medium}px) {
    padding: 0;
    align-items: start;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: ${spacing.xxsmall};
`;

export const ModalInner = styled.div<{ topPadding?: boolean }>`
  box-sizing: border-box;
  flex-grow: 1;
  padding: 0 ${spacing.mediumLow};
  margin-bottom: ${spacing.mediumLow};
  width: 100%;
  overflow: visible;
  ${({ topPadding }) => topPadding && `padding-top: ${spacing.mediumLow}`};

  @media (max-width: ${breakpoint.medium}px) {
    padding: 0 ${spacing.small};
    margin-bottom: ${spacing.small};
    ${({ topPadding }) => topPadding && `padding-top: ${spacing.small}`};
  }
`;

export const ModalBody = styled.div<{ fullScreen?: boolean; maxWidth?: string }>`
  display: flex;
  flex-direction: column;
  background-color: ${colors.base.white};

  width: ${({ fullScreen }) => (fullScreen ? '100%' : 'min-content')};
  ${({ fullScreen }) => fullScreen && 'height: 100%'};
  max-height: calc(100vh - 50px);
  min-width: 650px;
  box-shadow: ${boxShadow};
  max-width: ${({ maxWidth }) => maxWidth ?? 'unset'};

  @media (max-width: ${breakpoint.medium}px) {
    width: 100%;
    height: 100svh;
    max-height: 100svh;
    min-width: unset;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: ${spacing.mediumLow};
  padding-left: ${spacing.mediumLow};
  padding-right: ${spacing.mediumLow};
  gap: ${spacing.xsmall};

  @media (max-width: ${breakpoint.small}px) {
    flex-direction: column-reverse;
    align-items: stretch;
    padding-bottom: ${spacing.small};
    padding-left: ${spacing.small};
    padding-right: ${spacing.small};
  }
`;

export const OverlayWrapper = styled(FloatingOverlay)`
  background-color: ${overlayColor};
  z-index: 100;
  display: grid;
  place-items: center;
`;
