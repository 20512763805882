import TagManager from 'react-gtm-module';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-unresolved
import { useRegisterSW } from 'virtual:pwa-register/react';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlag } from '@unleash/proxy-client-react';
import { useProfileV2 } from 'core/lib/Profile';
import { environment } from '#helper/environment';
import Loading from '#components/Loading';
import ErrorMessage from '#components/ErrorMessage';
import InstallDialogWrapper from '#components/InstallDialogWrapper';
import { AppClosedModal } from '#components/AppClosedModal';
import AppContainer from '#AppContainer';
import Main from '#Main';
import Menu from '#Menu';
import { LoadingWrapper } from './styles';
import './index.css';

const App = () => {
  const swRegRef = useRef<ServiceWorkerRegistration | null>(null);
  const { t, ready } = useTranslation();
  const { profile } = useProfileV2();
  const { isLoading, error } = useAuth0();

  const updateServiceWorkerRegistration = () => swRegRef.current?.update();

  const isClosed = useFlag('pwa-closed');

  useRegisterSW({
    immediate: true,
    onRegistered(registration) {
      if (registration) {
        swRegRef.current = registration;
      }
    },
    onRegisterError(error) {
      console.log('SW registration error', error);
    },
    onOfflineReady: () => {
      location.reload();
    },
  });

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', () => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'BuiltInInstallPrompt',
        },
      });
    });
  }, []);

  useEffect(() => {
    if (profile?.email && profile.userId && window.Intercom) {
      window.Intercom('boot', {
        app_id: environment.intercomAppId,
        email: profile.email,
        user_hash: profile.userId,
      });
    }
  }, [profile]);

  if (error) {
    return <ErrorMessage />;
  }

  if (isLoading) {
    return (
      <LoadingWrapper>
        <Loading text={ready ? t('loading.login') : 'Logging in'} />
      </LoadingWrapper>
    );
  }

  if (isClosed) {
    return <AppClosedModal />;
  }

  return (
    <AppContainer>
      <Main updateServiceWorker={updateServiceWorkerRegistration} />
      <Menu />
      <InstallDialogWrapper />
    </AppContainer>
  );
};

export default App;
