import styled from '@emotion/styled';
import { Container } from '../../../../Surfaces/Container';
import { spacing } from '../../../../../themes/stena-recycling/spacing';
import { colors } from '../../../../../themes';

export const ColumnFilterWrapper = styled.div`
  display: flex;
  align-items: left;
`;

export const FloatingContainer = styled(Container)`
  z-index: 100;
`;

export const ColumnItem = styled.div`
  width: 290px;
  padding: ${spacing.xmsmall};
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    background-color: ${colors.background.grey};
  }
`;
