import styled from '@emotion/styled';
import { spacing } from '#themes/stena-recycling';

export const StyledTableHeaderSorting = styled.div<{ isSortable: boolean }>`
  cursor: ${({ isSortable }) => (isSortable ? 'pointer' : 'default')};
  display: flex;
  align-items: center;
  gap: ${spacing.xxsmall};
`;

export const SortIconWrapper = styled.div`
  width: ${spacing.xsmall};
  height: ${spacing.xsmall};
`;
