import styled from '@emotion/styled';
import { breakpoint, colors, spacing, typography } from 'component-library';

export const MessageWrapper = styled.div`
  margin-top: ${spacing.small};
  width: 100%;
  padding: ${spacing.mediumLow};
  box-sizing: border-box;

  @media (max-width: ${breakpoint.medium}px) {
    padding: 0 ${spacing.small};
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  padding: ${spacing.xmsmall} ${spacing.xsmall} ${spacing.xmsmall} ${spacing.small};
  box-sizing: border-box;
  background-color: ${colors.alerts.warningTint3};
  border-radius: ${spacing.xxsmall};
`;

export const SnackbarMessage = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${spacing.xsmall};
  height: ${spacing.mediumLow};

  @media (max-width: ${breakpoint.medium}px) {
    align-items: flex-start;
    flex-direction: column;
    height: unset;
    gap: 0;
  }
`;

export const ModalImageContainer = styled.div`
  display: flex;
  width: 100%;
  padding: ${spacing.xxsmall} 0;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.monochrome.grey20};
  background: ${colors.secondary.greenTint1};
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xsmall};
`;

export const SubHeader = styled.div`
  ${typography.body.largeBold}
  margin-bottom: ${spacing.tiny};
`;

export const ModalList = styled.ul`
  padding: 0 0 0 20px;
  margin: 0;
`;

export const ModalListItem = styled.li`
  ${typography.body.large}
`;
