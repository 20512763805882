import React, { useEffect, useMemo } from 'react';
import {
  useFloating,
  useDismiss,
  useRole,
  useInteractions,
  type UseInteractionsReturn,
} from '@floating-ui/react';
import { type ModalAction, type ModalOptions } from './types';

type UseModalReturn = {
  open: boolean;
  onOpenChange?: (open: boolean) => void;
  onClose?: () => void;
  disableClose?: boolean;
  secondaryAction?: ModalAction;
  primaryAction?: ModalAction;
  fullScreen?: boolean;
  maxWidth?: string;
} & ReturnType<typeof useFloating> &
  UseInteractionsReturn;

export const useModal = ({
  open,
  onOpenChange,
  onClose,
  secondaryAction,
  primaryAction,
  maxWidth,
  fullScreen,
  disableClose,
  closeOnClickOutside,
  closeOnEscape = true,
}: ModalOptions): UseModalReturn => {
  const data = useFloating({
    open,
    onOpenChange,
  });

  useEffect(() => {
    const padding = open ? '0px' : '16px';

    document.documentElement.style.setProperty('--active-modal-padding', padding);
  }, [open]);

  const context = data.context;

  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown',
    outsidePress: closeOnClickOutside,
    escapeKey: closeOnEscape,
  });

  const role = useRole(context);

  const interactions = useInteractions([dismiss, role]);

  const val = useMemo(() => {
    const value: UseModalReturn = {
      open,
      ...interactions,
      ...data,
      onClose,
      onOpenChange,
      disableClose,
      secondaryAction,
      primaryAction,
      fullScreen,
      maxWidth,
    };

    return value;
  }, [
    open,
    interactions,
    data,
    onClose,
    onOpenChange,
    disableClose,
    secondaryAction,
    primaryAction,
    fullScreen,
    maxWidth,
  ]);

  return val;
};

type ContextType = ReturnType<typeof useModal> | null;

export const ModalContext = React.createContext<ContextType>(null);

export const useModalContext = () => {
  const context = React.useContext(ModalContext);

  if (context === null) {
    throw new Error('Modal components must be wrapped in <Modal />');
  }

  return context;
};
