import { ExternalLink, Heading, ModalV2, Paragraph } from 'component-library';
import { createPortal } from 'react-dom';
import { useEventTracking } from 'core/lib/hooks/useEventTracking';
import { environment } from '#helper/environment';
import ModalImage from '../../assets/images/pwa-to-portal.png';
import { ModalContainer, ModalImageContainer } from './styles';

export const AppClosedModal = () => {
  const { trackEventAsync } = useEventTracking();

  return (
    <>
      {createPortal(
        <ModalV2
          open={true}
          disableClose={true}
          primaryAction={{
            label: 'Go to the service portal',
            action: async () => {
              await trackEventAsync({ event: 'pwa_to_portal_click' });
              window.location.href = `${environment.portalUrl}/pickups`;
            },
            icon: <ExternalLink />,
          }}
        >
          <ModalContainer>
            <Heading as="h1" variant="large">
              The app is closed down
            </Heading>
            <Paragraph variant="large">
              Please use our service portal for ordering pickups instead.
            </Paragraph>
            <ModalImageContainer>
              <img width={200} src={ModalImage} alt="" />
            </ModalImageContainer>
          </ModalContainer>
        </ModalV2>,
        document.body,
      )}
    </>
  );
};
