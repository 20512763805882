import styled from '@emotion/styled';
import { Button } from '#components/Input/Button';
import { breakpoint, colors, spacing, typography } from '#themes/stena-recycling';

export const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${spacing.small};
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;

  @media (max-width: ${breakpoint.large}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const PaginationButtonWrapper = styled.div`
  display: flex;
  gap: ${spacing.xxsmall};

  @media (max-width: ${breakpoint.large}px) {
    order: -1;
  }
`;

export const PageInfoWrapper = styled.div`
  display: flex;
  gap: ${spacing.xsmall};
  flex-direction: column;
`;

const activeStyles = `
    background-color: ${colors.primary.blue};
    color: ${colors.base.white};

    &:hover:not(:disabled) {
      background-color: ${colors.primary.blueShade1};
      color: ${colors.base.white};
    }
  `;

export const PageNumberButton = styled(Button)<{ isActive: boolean }>`
  min-width: 34px;

  &:hover:not(:disabled) {
    border-color: ${colors.primary.blueShade1};
  }

  ${({ isActive }) => (isActive ? activeStyles : '')}
`;

export const ResultsPerPageWrapper = styled.div`
  display: flex;
  gap: ${spacing.xsmall};
  align-items: center;
`;

export const StyledEllipsis = styled.div`
  width: ${spacing.xsmall};
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 12px;
  color: ${colors.monochrome.grey50};
  font-size: ${typography.heading.desktop.medium.fontSize};
  user-select: none;
`;

export const GoToPageWrapper = styled.div`
  display: flex;
  gap: ${spacing.xsmall};
  align-items: center;
  justify-content: flex-end;
`;
