import { Button, ExternalLink, Paragraph } from 'component-library';
import { useTranslation } from 'react-i18next';
import { useFlag } from '@unleash/proxy-client-react';
import { useEventTracking } from 'core/lib/hooks/useEventTracking';
import { MessageContainer, MessageWrapper } from './styles';
import { navigateToPortalPickups } from './utils';

export const OPV2Message = () => {
  const { t } = useTranslation();

  const ffOPV2Modal = useFlag('pwa-opv2-modal');

  const { trackEventAsync } = useEventTracking();

  if (!ffOPV2Modal) {
    return null;
  }

  return (
    <MessageWrapper>
      <MessageContainer>
        <Paragraph mb="none" variant="large" bold>
          {t('order.pickups.pwa.close.title')}
        </Paragraph>
        <Paragraph mb="xsmall" variant="medium">
          {t('order.pickups.pwa.close.text')}
        </Paragraph>
        <Button
          variant="primary"
          fullWidth={true}
          onClick={async () => {
            await trackEventAsync({ event: 'pwa_to_portal_click' });
            navigateToPortalPickups();
          }}
          icon={<ExternalLink />}
          iconPlacement="after"
        >
          {t('order.pickups.on.portal.confirmation.cta')}
        </Button>
      </MessageContainer>
    </MessageWrapper>
  );
};
