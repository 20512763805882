import styled from '@emotion/styled';
import { colors, spacing, typography } from '../../../themes';
import { boxShadow } from '../../helpers/styleHelpers';

export const TooltipContentStyled = styled.div<{ isOpen: boolean }>`
  transition:
    visibility 0.15s,
    opacity 0.15s linear;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  background-color: ${colors.base.white};
  padding: ${spacing.xsmall};
  max-width: 300px;
  box-shadow: ${boxShadow};
  border-radius: 4px;
  ${typography.body.medium};
  color: ${colors.base.black};
`;
